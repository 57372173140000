import React from 'react';
import { positions } from '../../utils/constants';
import { SalaryData } from '../../utils/types';
import trackEvent from '../../utils/eventTracking';
import WebApp from '@twa-dev/sdk';

interface CurrentSalaryStepProps {
  existingData: SalaryData;
  startEdit: () => void;
  userId: number | null;
}

const CurrentSalaryStep: React.FC<CurrentSalaryStepProps> = ({ existingData, startEdit, userId }) => {
  const getPositionIcon = (positionName: string) => {
    const position = positions.find(p => p.name === positionName);
    return position ? position.icon : '💼'; // Используем '💼' как иконку по умолчанию
  };

  const handleStartEdit = () => {
    if (userId) trackEvent('EDIT_STARTED', userId);
    startEdit();
  };

  const formatSalary = (salary: string): string => {
    return salary.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInvite = () => {
    if (userId !== null) {
      trackEvent('INVITE_CLICKED', userId);
      const referralLink = `https://t.me/itleaderboardbot/salary?startapp=USER_${userId}`;
      const shareText = `Присоединяйся к нашему сообществу IT-специалистов и узнай, какие зарплаты на рынке!`;
      WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(shareText)}`);
    }
  };

  return (
    <>
      <div className="existing-data card animate-in">
        <h2>Моя зарплата</h2>
        <p>Данные о текущей зарплате</p>
        <div className="data-item">
          <span className="data-label">Зарплата</span>
          <span className="data-value">{formatSalary(existingData.salary)} {existingData.currency}</span>
        </div>
        <div className="data-item">
          <span className="data-label">Должность</span>
          <span className="data-value">
            {getPositionIcon(existingData.position)} {existingData.position}
          </span>
        </div>
        {existingData.experience && (
          <div className="data-item">
            <span className="data-label">Опыт работы</span>
            <span className="data-value">{existingData.experience} {getYearsText(existingData.experience)}</span>
          </div>
        )}
        <div className="data-item">
          <span className="data-label">Компания</span>
          <span className="data-value">{existingData.company}</span>
        </div>
        <button onClick={handleStartEdit} className="tg-button">Изменить</button>
      </div>
      <div className="invitation-card card animate-in">
        <h2>Скоро будет доступно</h2>
        <p>Данные по зарплатам на рынке скоро появятся. Мы пришлем уведомление, как только они будут готовы! 🚀</p>
        <button onClick={handleInvite} className="tg-button invite-button">Пригласить друзей и коллег</button>
      </div>
    </>
  );
};

// Вспомогательная функция для правильного склонения слова "год"
const getYearsText = (experience: string) => {
  const years = parseInt(experience, 10);
  if (years % 10 === 1 && years % 100 !== 11) {
    return 'год';
  } else if ([2, 3, 4].includes(years % 10) && ![12, 13, 14].includes(years % 100)) {
    return 'года';
  } else {
    return 'лет';
  }
};

export default CurrentSalaryStep;