import React, { useEffect } from 'react';
import { StepProps } from '../../utils/types';
import trackEvent from '../../utils/eventTracking';

const CompanyInputStep: React.FC<Pick<StepProps, 'prevStep' | 'company' | 'setCompany' | 'submitData' | 'userId'>> =
  ({ prevStep, company, setCompany, submitData, userId }) => {

  useEffect(() => {
    if (userId !== null) {
      trackEvent('COMPANY_INPUT_VIEWED', userId);
    }
  }, [userId]);

  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCompany = e.target.value;
    setCompany(newCompany);
  };

  const handleSubmit = () => {
    if (userId !== null) {
      trackEvent('COMPANY_SUBMITTED', userId, company);
    }
    submitData();
  };

  return (
    <div className="company-input card animate-in">
      <h2>Введи название текущей компании</h2>
      <div className="input-group">
        <input
          type="text"
          value={company}
          onChange={handleCompanyChange}
          placeholder="Название компании"
        />
      </div>
      <div className="button-group">
        <button onClick={prevStep} className="back-button">Назад</button>
        <button onClick={handleSubmit} disabled={!company} className="main-button">Сохранить</button>
      </div>
    </div>
  );
};

export default CompanyInputStep;