export const positions = [
  { name: 'Software Engineer', icon: '💻' },
  { name: 'Team Lead', icon: '👥' },
  { name: 'Data Scientist', icon: '📊' },
  { name: 'Product Manager', icon: '📱' },
  { name: 'UX Designer', icon: '🎨' },
  { name: 'DevOps Engineer', icon: '🛠️' },
  { name: 'QA Engineer', icon: '🔍' },
  { name: 'Technical Analyst', icon: '📈' },
  { name: 'Other', icon: '➕' }
];

export const currencies = [
  // Existing currencies
  'RUB', 'USD', 'EUR', 'AED',

  // CIS countries
  'BYN', // Belarusian Ruble
  'KZT', // Kazakhstani Tenge
  'UAH', // Ukrainian Hryvnia
  'UZS', // Uzbekistani Som
  'AZN', // Azerbaijani Manat
  'AMD', // Armenian Dram
  'KGS', // Kyrgyzstani Som
  'TJS', // Tajikistani Somoni
  'TMT', // Turkmenistani Manat
  'GEL', // Georgian Lari
  'MDL', // Moldovan Leu

  // Asian countries
  'CNY', // Chinese Yuan
  'JPY', // Japanese Yen
  'KRW', // South Korean Won
  'SGD', // Singapore Dollar
  'HKD', // Hong Kong Dollar
  'INR', // Indian Rupee
  'IDR', // Indonesian Rupiah
  'MYR', // Malaysian Ringgit
  'THB', // Thai Baht
  'VND', // Vietnamese Dong
  'PHP'  // Philippine Peso
];

export const prod = true;
export const serverBaseUrl = prod ? '/api' : 'http://localhost:5000';