import React, { useState, useEffect } from 'react';
import './App.css';
import WebApp from '@twa-dev/sdk';
import WelcomeStep from './components/salarySave/WelcomeStep';
import SalaryInputStep from './components/salarySave/SalaryInputStep';
import PositionSelectStep from './components/salarySave/PositionSelectStep';
import CompanyInputStep from './components/salarySave/CompanyInputStep';
import ExperienceInputStep from './components/salarySave/ExperienceInputStep';
import SuccessStep from './components/salarySave/SuccessStep';
import CurrentSalaryStep from './components/salarySave/CurrentSalaryStep';
import ProgressBar from './components/ProgressBar';
import { StepProps, SalaryData } from './utils/types';
import { prod, serverBaseUrl } from './utils/constants';
import trackEvent from './utils/eventTracking';

const STEP_NAMES = {
  WELCOME: 'WELCOME',
  SALARY_INPUT: 'SALARY_INPUT',
  POSITION_SELECT: 'POSITION_SELECT',
  EXPERIENCE_INPUT: 'EXPERIENCE_INPUT',
  COMPANY_INPUT: 'COMPANY_INPUT',
  SUCCESS: 'SUCCESS',
};

const TOTAL_STEPS = 6; // Включая новый шаг опыта и шаг успешного завершения

function App() {
  const [theme, setTheme] = useState(WebApp.colorScheme);
  const [step, setStep] = useState(-1);
  const [salary, setSalary] = useState('');
  const [currency, setCurrency] = useState('RUB');
  const [position, setPosition] = useState('');
  const [customPosition, setCustomPosition] = useState('');
  const [experience, setExperience] = useState('');
  const [company, setCompany] = useState('');
  const [userId, setUserId] = useState<number | null>(null);
  const [existingData, setExistingData] = useState<SalaryData | null>(null);
  const [referralCode, setReferralCode] = useState<string | null>(null);

  useEffect(() => {
    WebApp.onEvent('themeChanged', () => {
      setTheme(WebApp.colorScheme);
    });

    // Устанавливаем CSS-переменные для темы
    const root = document.documentElement;
    Object.entries(WebApp.themeParams).forEach(([key, value]) => {
      root.style.setProperty(`--tg-theme-${key.replace(/_/g, '-')}`, value);
    });

    // Получаем параметры запуска
    const initData = WebApp.initData;
    const startParam = new URLSearchParams(initData).get('start_param');
    if (startParam) {
      setReferralCode(startParam);
    }
  }, []);

  useEffect(() => {
    if (WebApp.initDataUnsafe.user) {
      setUserId(WebApp.initDataUnsafe.user.id);
    } else {
      setUserId(0);
    }
  }, []);

  useEffect(() => {
    if (userId !== null) {
      fetchExistingData();
      trackEvent('APP_OPENED', userId);
      saveUserData();
    }
  }, [userId]);

  const saveUserData = async () => {
    if (userId === null) return;

    try {
      const response = await fetch(`${serverBaseUrl}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          referrer_id: referralCode
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save user data');
      }

      console.log('User data saved successfully');
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  const fetchExistingData = async () => {
    try {
      const response = await fetch(`${serverBaseUrl}/salaries/${userId}`);
      if (response.ok) {
        const data: SalaryData = await response.json();
        setExistingData(data);
        setSalary(data.salary);
        setCurrency(data.currency);
        setPosition(data.position);
        setCompany(data.company);
        setExperience(data.experience || ''); // Set experience from existing data
      }
    } catch (error) {
      console.error('Error fetching existing data:', error);
    } finally {
      setStep(0); // Set to 0 after attempting to fetch, regardless of result
    }
  };

  const getStepName = (stepNumber: number): string => {
    switch (stepNumber) {
      case 0: return STEP_NAMES.WELCOME;
      case 1: return STEP_NAMES.SALARY_INPUT;
      case 2: return STEP_NAMES.POSITION_SELECT;
      case 3: return STEP_NAMES.COMPANY_INPUT;
      case 4: return STEP_NAMES.SUCCESS;
      default: return 'UNKNOWN_STEP';
    }
  };

  const nextStep = () => {
    const newStep = step + 1;
    setStep(newStep);
    if (userId) {
      const toStep = getStepName(newStep);
      trackEvent('STEP_CHANGED', userId, getStepName(newStep));
    }
  };

  const prevStep = () => {
    const newStep = step - 1;
    setStep(newStep);
    if (userId) {
      const toStep = getStepName(newStep);
      trackEvent('STEP_CHANGED', userId, getStepName(newStep));
    }
  };

  const startEdit = () => {
    setStep(1);
    if (userId) trackEvent('EDIT_STARTED', userId);
  };

  const viewData = () => {
    fetchExistingData();
    setStep(0);
    if (userId) trackEvent('VIEW_DATA', userId);
  };

  const submitData = async () => {
    try {
      const finalPosition = position === 'Other' ? customPosition : position;
      const response = await fetch(`${serverBaseUrl}/salaries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          salary,
          currency,
          position: finalPosition,
          experience,
          company
        }),
      });
      if (response.ok) {
        if (userId) trackEvent('DATA_SUBMITTED', userId);
        nextStep();
      } else {
        if (userId) trackEvent('SUBMISSION_ERROR', userId);
        alert('Error submitting data');
      }
    } catch (error) {
      console.error('Error:', error);
      if (userId) trackEvent('SUBMISSION_ERROR', userId);
      alert('Error submitting data');
    }
  };

  const stepProps: StepProps = {
    nextStep,
    prevStep,
    salary,
    setSalary,
    currency,
    setCurrency,
    position,
    setPosition,
    customPosition,
    setCustomPosition,
    experience,
    setExperience,
    company,
    setCompany,
    submitData,
    userId,
    referralCode
  };

  const renderStep = () => {
    if (step === -1) {
      return <div className="loading">Загрузка...</div>;
    }

    const stepContent = (() => {
      if (step === 0 && existingData) {
        return <CurrentSalaryStep existingData={existingData} startEdit={startEdit} userId={userId} />;
      }

      switch(step) {
        case 0: return <WelcomeStep {...stepProps} />;
        case 1: return <SalaryInputStep {...stepProps} />;
        case 2: return <PositionSelectStep {...stepProps} />;
        case 3: return <ExperienceInputStep {...stepProps} />;
        case 4: return <CompanyInputStep {...stepProps} />;
        case 5: return <SuccessStep onViewData={viewData} userId={userId} />;
        default: return null;
      }
    })();

    const showProgressBar = !(step === 0 && existingData);

    return (
      <div key={step} className="step-transition">
        {showProgressBar && <ProgressBar currentStep={step} totalSteps={TOTAL_STEPS} />}
        {stepContent}
      </div>
    );
  };

  return (
    <div className="App">
      {renderStep()}
    </div>
  );
}

export default App;