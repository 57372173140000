import React from 'react';
import trackEvent from '../../utils/eventTracking';

interface SuccessStepProps {
  onViewData: () => void;
  userId: number | null;
}

const SuccessStep: React.FC<SuccessStepProps> = ({ onViewData, userId }) => {
  const handleViewData = () => {
    if (userId) trackEvent('VIEW_DATA_CLICKED', userId);
    onViewData();
  };

  return (
    <div className="success card animate-in">
      <h2>Спасибо! 🎉</h2>
      <p>Мы отправим уведомление, когда данные по зарплатам в IT будут готовы к просмотру</p>
      <button onClick={handleViewData} className="tg-button">Посмотреть мои данные</button>
    </div>
  );
};

export default SuccessStep;