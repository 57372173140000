import React, { useEffect } from 'react';
import { StepProps } from '../../utils/types';
import trackEvent from '../../utils/eventTracking';

const WelcomeStep: React.FC<Pick<StepProps, 'nextStep' | 'userId' | 'referralCode'>> = ({ nextStep, userId, referralCode }) => {
  useEffect(() => {
    if (userId !== null) {
      trackEvent('WELCOME_SCREEN_VIEWED', userId, referralCode || undefined);
    }
  }, [userId, referralCode]);

  const handleStart = () => {
    if (userId !== null) {
      trackEvent('WELCOME_START_CLICKED', userId, referralCode || undefined);
    }
    nextStep();
  };

  return (
    <div className="welcome card animate-in">
      <h1>Привет! 👋</h1>
      <p>Пожалуйста, введи данные о своей зарплате, чтобы получить доступ к полной базе зарплат в IT</p>
      <p>Данные будут сохранены <b>анонимно</b></p>
      {referralCode && <p>Вы пришли по приглашению друга!</p>}
      <button onClick={handleStart} className="main-button">Начать</button>
    </div>
  );
};

export default WelcomeStep;