import React from 'react';
import { StepProps } from '../../utils/types';
import { positions } from '../../utils/constants';
import trackEvent from '../../utils/eventTracking';

const PositionSelectStep: React.FC<Pick<StepProps, 'nextStep' | 'prevStep' | 'position' | 'setPosition' | 'customPosition' | 'setCustomPosition' | 'userId'>> =
  ({ nextStep, prevStep, position, setPosition, customPosition, setCustomPosition, userId }) => {

  const handlePositionSelect = (positionName: string) => {
    setPosition(positionName);
    if (userId) trackEvent('POSITION_SELECTED', userId, positionName);
  };

  const handleCustomPositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomPosition(e.target.value);
  };

  const handleNext = () => {
    const finalPosition = position === 'Other' ? customPosition : position;
    if (userId) trackEvent('POSITION_ENTERED', userId, finalPosition);
    nextStep();
  };

  return (
    <div className="position-select card animate-in">
      <h2>Выбери свою роль</h2>
      <div className="position-list">
        {positions.map(pos => (
          <div
            key={pos.name}
            className={`position-item ${position === pos.name ? 'active' : ''}`}
            onClick={() => handlePositionSelect(pos.name)}
          >
            <span className="position-icon">{pos.icon}</span>
            <span className="position-name">{pos.name}</span>
          </div>
        ))}
      </div>
      {position === 'Other' && (
        <input
          type="text"
          value={customPosition}
          onChange={handleCustomPositionChange}
          placeholder="Введите вашу роль"
          className="custom-position-input"
        />
      )}
      <div className="button-group">
        <button onClick={prevStep} className="back-button">Назад</button>
        <button onClick={handleNext} disabled={!position || (position === 'Other' && !customPosition)} className="main-button">Далее</button>
      </div>
    </div>
  );
};

export default PositionSelectStep;