import { serverBaseUrl } from './constants';

const trackEvent = async (eventName: string, userId: number, eventValue?: string) => {
  try {
    const response = await fetch(serverBaseUrl + '/events', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event_name: eventName,
        user_id: userId,
        event_value: eventValue,
      }),
    });
    if (!response.ok) {
      console.error('Failed to track event:', eventName);
    }
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

export default trackEvent;