import React from 'react';
import { StepProps } from '../../utils/types';
import { currencies } from '../../utils/constants';
import trackEvent from '../../utils/eventTracking';

const SalaryInputStep: React.FC<Pick<StepProps, 'nextStep' | 'prevStep' | 'salary' | 'setSalary' | 'currency' | 'setCurrency' | 'userId'>> =
  ({ nextStep, prevStep, salary, setSalary, currency, setCurrency, userId }) => {

  const formatNumber = (num: string) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const unformatNumber = (num: string) => {
    return num.replace(/,/g, "");
  };

  const handleSalaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = unformatNumber(e.target.value);
    if (/^\d*$/.test(value)) {
      const numericValue = parseInt(value, 10);
      if (isNaN(numericValue)) {
        setSalary('');
      } else {
        setSalary(formatNumber(numericValue.toString()));
      }
    }
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = e.target.value;
    setCurrency(newCurrency);
    if (userId) trackEvent('CURRENCY_CHANGED', userId, newCurrency);
  };

  const handleNext = () => {
    if (userId) trackEvent('SALARY_ENTERED', userId, `${unformatNumber(salary)}_${currency}`);
    nextStep();
  };

  return (
    <div className="salary-input card animate-in">
      <h2>Введи сумму текущей зарплаты</h2>
      <p>В месяц, без бонусов, <b>на руки</b></p>
      <div className="input-group">
        <input
          type="text"
          inputMode="numeric"
          value={salary}
          onChange={handleSalaryChange}
          placeholder="Сумма"
        />
        <select value={currency} onChange={handleCurrencyChange}>
          {currencies.map(curr => (
            <option key={curr} value={curr}>{curr}</option>
          ))}
        </select>
      </div>
      <div className="button-group">
        <button onClick={prevStep} className="back-button">Назад</button>
        <button onClick={handleNext} disabled={!salary} className="main-button">Далее</button>
      </div>
    </div>
  );
};

export default SalaryInputStep;