import React from 'react';
import { StepProps } from '../../utils/types';
import trackEvent from '../../utils/eventTracking';

const ExperienceInputStep: React.FC<Pick<StepProps, 'nextStep' | 'prevStep' | 'experience' | 'setExperience' | 'userId'>> =
  ({ nextStep, prevStep, experience, setExperience, userId }) => {

  const handleExperienceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue) && numericValue <= 50) {
        setExperience(value);
      } else if (value === '') {
        setExperience('');
      }
    }
  };

  const handleNext = () => {
    if (userId) trackEvent('EXPERIENCE_ENTERED', userId, experience);
    nextStep();
  };

  return (
    <div className="experience-input card animate-in">
      <h2>Укажи свой опыт работы</h2>
      <p>Сколько лет ты работаешь в IT? (максимум 50 лет)</p>
      <div className="input-group">
        <input
          type="text"
          inputMode="numeric"
          pattern="\d*"
          value={experience}
          onChange={handleExperienceChange}
          placeholder="Годы опыта"
          maxLength={2}
        />
      </div>
      <div className="button-group">
        <button onClick={prevStep} className="back-button">Назад</button>
        <button onClick={handleNext} disabled={!experience} className="main-button">Далее</button>
      </div>
    </div>
  );
};

export default ExperienceInputStep;